import React, { useState, useEffect } from "react";
import "./Filters.css";
import { BsFilterLeft } from "react-icons/bs";
import axios from '../../utilities/axios'
import { json } from "react-router-dom";
import HotelsLoader from "../../utilities/HotelsLoader";

const Filters = ({value, historyData, sendData}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [categoryPick, setcCategoryPick] = useState('')
  const [amenities, setAmenities] = useState([]);
  const [filterLoader, setFilterloader] = useState(false)
  const [amenities2, setAmenities2] = useState([]);
  const [showBtn, setShowbtn] = useState(false);
  const [showBtn2, setShowbtn2] = useState();
  const [cityTag, setCityTag] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFixed, setFixed] = useState(false);
  const [filterObj, setFilterObj] = useState({
    priceStartRange: 0,     
    priceEndRange: 0,
    amenities: [],
    // selectedCity: "",
  });
  const uniqueAmenities2 = [];

  // console.log("historyData", historyData.availableHotel)

  useEffect(()=>{
    setCityTag(historyData)
  },[])

  const uniqueAmenities = [];

  useEffect(()=>{
    for(const city of cityTag){
      uniqueAmenities2.push(city.amenities)
    }
    
    // Iterate over the data and extract unique amenities
    uniqueAmenities2.forEach((sublist) => {
      sublist.forEach((item) => {
        const amenity = item.amenity;
        const amenityId = item.amenityId;
        const existingAmenity = uniqueAmenities.find((uniqueItem) => uniqueItem.amenityId === amenityId);
        if (!existingAmenity) {
          uniqueAmenities.push({ amenityId, amenity });
        }
      });
    });
  
  // console.log("uniqueAmenities==>", uniqueAmenities)
  setAmenities2(uniqueAmenities)

},[cityTag])

// console.log("amenities2==>", amenities2)

//   const uniqueAmenities = [];
// const amenityIds = new Set();

// for (const amenity of amenities) {
//   if (!amenityIds.has(amenity.id)) {
//     uniqueAmenities.push(amenity);
//     amenityIds.add(amenity.id);
//   }
// }

  const addAmenities = (e) => {
    const { value, checked } = e.target;
    let updatedAmenities;

    if (checked) {
      updatedAmenities = [...amenities, value];
    } else {
      updatedAmenities = amenities.filter((amenity) => amenity !== value);
    }

    const updatedFilterObj = { ...filterObj, amenities: updatedAmenities };
    setAmenities(updatedAmenities);
    setFilterObj(updatedFilterObj);

    if (updatedFilterObj.amenities.length !== 0) {
      setShowbtn2(true);
    } else {
      setShowbtn2(false);
    }
  };

  const getFilterPrice = (e) => {
    // e.preventDefault()
    //   const { value, checked } = e.target;
    //   let updatedAmenities;
    // const startEndPrice = value.split(',')

    //   const startPrice = parseFloat(startEndPrice[0])
    //   const endPrice = parseFloat(startEndPrice[1])

    //   if(checked){
    //     filterObj.priceStartRange = startPrice
    //     filterObj.priceEndRange = endPrice
    //   }else{
    //     filterObj.priceStartRange = 0
    //     filterObj.priceEndRange = 0
    //   }
    const { value, checked } = e.target;
    const startEndPrice = value.split(",");
    const startPrice = parseFloat(startEndPrice[0]);
    const endPrice = parseFloat(startEndPrice[1]);

    if (checked) {
      setFilterObj((prevFilterObj) => ({
        ...prevFilterObj,
        priceStartRange: startPrice,
        priceEndRange: endPrice,
      }));
      setShowbtn(true);
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        if (checkbox.value !== value) {
          checkbox.checked = false;
        }
      });
    } else {
      setFilterObj((prevFilterObj) => ({
        ...prevFilterObj,
        priceStartRange: 0,
        priceEndRange: 0,
      }));
      setShowbtn(false);
    }

    // if (checked) {
    //   updatedAmenities = [...price, startPrice,endPrice];

    // } else {
    //   updatedAmenities = price.filter((amenity) => amenity !== startPrice && amenity !== endPrice);

    // }

    // const updatedFilterObj = { ...filterObj, price: updatedAmenities };
    // setPrice(updatedAmenities);
    // setFilterObj(updatedFilterObj);

    // if(filterObj.priceEndRange > 0 ){
    //   setShowbtn(true)

    // }else{
    //   setShowbtn(false)
    // }
  };

  let advancedFilter = filterObj ;

  const searchFilteredOpt = async () => { 
    setFilterloader(true)

    let filterResult = await axios.get(`/registrations/getAdvanceSearch`,{
      params:{
        advancedFilter:advancedFilter,
        genFilter:value,
        sourceType:"TiptopgoWeb",
        pageSize:12,
        page:1,
        propertyTypeFilter: categoryPick
      }
    })
    setFilterloader(false)
    
    console.log("filterResult", filterResult.data)
    
    sendData(filterResult.data)
  }
 

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const fixedThreshold = window.innerHeight * 0.1; // 10% of the viewport height

      if (scrollPosition >= fixedThreshold) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleAllPropertyType = async (category) => {
    setFilterloader(true)
    setcCategoryPick(category)
    let filterResult = await axios.get(`/registrations/hotels?genFilter=${value}&sourceType=TiptopgoWeb&pageSize=12&page=1`)
    sendData(filterResult.data, category)
    setFilterloader(false)
    // closeModal()
};


  const handlePropertyTypeClick = async (category) => {
    setFilterloader(true)
    setcCategoryPick(category)
    let filterResult = await axios.get(`/registrations/getAdvanceSearch?genFilter=${value}&sourceType=TiptopgoWeb&propertyTypeFilter=${category}`)
    sendData(filterResult.data, category)
    setFilterloader(false)
    // closeModal()
};

const handleCheckboxChange = () => {
  setCheckboxChecked(true);
  setCheckbox1Checked(false);
  setCheckbox2Checked(false);
};

const handleCheckbox1Change = () => {
  setCheckbox1Checked(true);
  setCheckbox2Checked(false);
  setCheckboxChecked(false) 
};

const handleCheckbox2Change = () => {
  setCheckbox2Checked(true);
  setCheckbox1Checked(false);
  setCheckboxChecked(false) 
};


  return (
    <div className='parent_search' >
      {/* <div className='searchFilter'>
        <div className='filter_item1'>
          <h2 className='filterTitle'>
            {" "}
            <BsFilterLeft /> Search Filters
          </h2>
          <p>Propert type</p>
          {
            filterLoader ? <HotelsLoader /> :  <div className='field'>       
            <div className='hotelProps hotelProps2'>
                <input type='checkbox'
                 checked={checkboxChecked}
                 onClick={() =>{ handleAllPropertyType('all property'); handleCheckboxChange()}} 
                 />
                <span> All Property </span>
              </div>
            <div className='hotelProps hotelProps2'>
                <input type='checkbox'
                 checked={checkbox1Checked}
                 onClick={() =>{ handlePropertyTypeClick("hotel"); handleCheckbox1Change()}} 
                 />
                <span> Hotels </span>
              </div>
              <div className='hotelProps'>
                <input type='checkbox'
                 onClick={() =>{ handlePropertyTypeClick("apartment"); handleCheckbox2Change()}}
                 checked={checkbox2Checked}
                 />
                <span> Apartments </span>
              </div>
            </div>
          }
         
          
        </div>
        <div className='filter_item1'>
          <p>What is your Budget</p>
          <ul>
            <div className='search_amenities'>
              <input
                type='checkbox'
                id='bar'
                value='0,5000'
                checked={
                  filterObj.priceStartRange === 0 &&
                  filterObj.priceEndRange === 5000
                }
                onChange={getFilterPrice}
              />
              <li> &#x20A6;0 - &#x20A6;5,000</li>
            </div>

            <div className='search_amenities'>
              <input
                type='checkbox'
                id='bar'
                value='6000,10000'
                checked={
                  filterObj.priceStartRange === 6000 &&
                  filterObj.priceEndRange === 10000
                }
                onChange={getFilterPrice}
              />
              <li> &#x20A6;6,000 - &#x20A6;10,000</li>
            </div>

            <div className='search_amenities'>
              <input
                type='checkbox'
                id='bar'
                value='11000,16000'
                checked={
                  filterObj.priceStartRange === 11000 &&
                  filterObj.priceEndRange === 16000
                }
                onChange={getFilterPrice}
              />
              <li> &#x20A6;11,000 - &#x20A6;16,000</li>
            </div>

            <div className='search_amenities'>
              <input
                type='checkbox'
                id='bar'
                value='17000,27000'
                checked={
                  filterObj.priceStartRange === 17000 &&
                  filterObj.priceEndRange === 27000
                }
                onChange={getFilterPrice}
              />
              <li> &#x20A6;17,000 - &#x20A6;27,000</li>
            </div>

            <div className='search_amenities'>
              <input
                type='checkbox'
                id='bar'
                value='28000,10000000'
                checked={
                  filterObj.priceStartRange === 28000 &&
                  filterObj.priceEndRange === 10000000
                }
                onChange={getFilterPrice}
              />
              <li> &#x20A6;28,000 and above </li>
            </div>
          </ul>
          {showBtn ? <button onClick={searchFilteredOpt}>Search</button> : null}
        </div>

        <div className='filter_item1'>
          <p>Only show hotels with amenities</p>
          <ul>
            {
              amenities2 && amenities2.map((amenity, i)=>(
                <div className='search_amenities' key={i}>
              <input
                type='checkbox'
                id={amenity.amenityId}
                value={amenity.amenity}
                onChange={addAmenities}
              />
              <li> {amenity.amenity}</li>
            </div>
              ))
            }
            
          </ul>
          {showBtn2 ? <button onClick={searchFilteredOpt}>Search</button> : null}
        </div>
      </div> */}
 
      {/* <div className='filter_items2'>
        <h2>Select Area in {value}</h2>
        <ul>
         
            {
              cityTag && cityTag.map((location, i)=>(
                <div className='area_hotel' key={i}>
                <input type='checkbox' id='bar' />
            <div className='area_hotel_text2'>
              <li> Hotels in {location.location} </li>
              <span>120 hotels</span>
            </div> 
            </div>
              ))
            }
        
        </ul>
      </div>  */}
    </div>
  );
};

export default Filters;
