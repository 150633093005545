import React, { useEffect, useState } from "react";
import axios from "../../utilities/axios";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import moment from "moment";

const TrendingPost = () => {
  const [allBlogPost, setAllBlogPost] = useState([]);

  useEffect(() => {
    const getAllBlogPost = async () => {
      const getALlBlog = await axios.get("/registrations/getAllBlogs");
      setAllBlogPost(getALlBlog.data.data);
      // console.log("getALlBlog", getALlBlog.data.data);
    };
    getAllBlogPost();
  }, []);

  const trendingBlogPost =
    allBlogPost &&
    allBlogPost.filter((blogpost) => {
      return blogpost.blogType === "trending";
    });

  // console.log("trendingBlogPost", trendingBlogPost);

  // createdDateTime

  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };
  return (
    <div className="newpostDiv">
      <h2>Trending Post</h2>
      <div className="parentTrendingPost">
        {trendingBlogPost &&
          trendingBlogPost?.map((blogContent, index) => (
            <div className="someTrendingPost" key={index}>
              <div className="trendingpostimg">
                <img src={blogContent.mainImage} alt="" />
              </div>
              <div className="trendingdetails">
                <span className="blogDate">
                  {moment(blogContent.createdDateTime).format("DD MMM YYYY")}
                </span>
                <span className="trendingBlogTitle">
                  {truncateText(blogContent?.title, 26)}
                </span>
                <p className="trendingContent">
                  {truncateText(blogContent?.firstMainParagraph, 150)}
                </p>
                <div className="trendingblogpostfooter">
                  <Link
                    to="/tiptopgo-blog-post"
                    state={{ blogContent }}
                    className="trendblogreadMore"
                  >
                    more <HiOutlineArrowNarrowRight />
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="blogsubscribemailparent">
        <div className="blogsubscribemail">
          <h3>Keep up with latest booking trends</h3>
          <p>
            Subscribe to our weekly newsletter and stay in the loop with the
            latest hospitality trends, insights, and exclusive offers.
          </p>
          <div>
            <form className="email">
              <input
                type="email"
                placeholder="email"
                name="emailAddress"
                id="emailAddress"
                className="subscribe"
              />

              <button>Subscribe</button>
              {/* <br />
          {formik.touched.emailAddress && formik.errors.emailAddress ? (
            <p className='error'>{formik.errors.emailAddress}</p>
          ) : null} */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingPost;
