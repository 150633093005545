import React, { useEffect, useState } from "react";
import Footer from "../../HomePage/Footer/Footer";
import NavBar from "../../HomePage/NavBar/NavBar";
import "./BlogPostHome.css";

import Blog from "../NewBlogDesign/Blog";
import { Link } from "react-router-dom";
import GetApp from "../../HomePage/Get App/GetApp";
import TrendingPost from "../TrendingPost.jsx/TrendingPost";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import axios from "../../utilities/axios";

const BlogPostHome = () => {
  const [allBlogPost, setAllBlogPost] = useState([]);
  useEffect(() => {
    const getAllBlogPost = async () => {
      const getALlBlog = await axios.get("/registrations/getAllBlogs");
      const filteredBlogPost = getALlBlog.data.data.filter(
        (filteredBlogPost) =>
          filteredBlogPost.status !== "suspend" &&
          filteredBlogPost.status !== "delete"
      );
      setAllBlogPost(filteredBlogPost);
      // console.log("getALlBlog", getALlBlog.data.data);
    };
    getAllBlogPost();
  }, []);

  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  return (
    <div>
      <NavBar />
      <section className="mainblogPostSection">
        <div className="parentBlogsect">
          <div className="parentCardPost">
            {allBlogPost &&
              allBlogPost.map((blogContent, index) => (
                <div className="firstGridSection">
                  <div className="firstSectimg_1">
                    <img src={blogContent.mainImage} alt="" />
                    <div className="contentContainer">
                      <h2 className="contentLabel">
                        {blogContent.blogCategory}
                      </h2>
                      <h1>{truncateText(blogContent?.title, 26)}</h1>
                      <p>
                        {" "}
                        {truncateText(blogContent?.firstMainParagraph, 150)}
                      </p>
                    </div>

                    <div className="blogpostfooter">
                      <Link
                        to="/tiptopgo-blog-post"
                        state={{ blogContent }}
                        className="blogreadMore"
                      >
                        more <HiOutlineArrowNarrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="secondGridSection">
            <TrendingPost />
          </div>
        </div>
      </section>

      {/* <Blog /> */}
      <GetApp />
      <Footer />
    </div>
  );
};

export default BlogPostHome;
