import React, { useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import { MdPlaylistRemove } from "react-icons/md";
import { FaBlog, FaEye, FaPlus } from "react-icons/fa";
import { GoEyeClosed } from "react-icons/go";
import axios from "../../../utilities/axios";
import { BookingLoader } from "../../../utilities/Loader";
import { ToastContainer, toast } from "react-toastify";

const BlogEditForm = ({ initialBlogData, onSubmit }) => {
  const [previewData, setPreviewData] = useState(null); // State to hold preview data
  const [showPreviewpanel, setShowPreviewPanel] = useState(false);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();
  const { state } = location; // Access the passed state
  console.log("State:", state);

  const notify = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setTimeout(() => {
      navigation("/tiptopgo-blog", {});
    }, 2000);
  };

  const handlePreview = (values) => {
    const isValid =
      values.title &&
      values.summary &&
      values.firstMainParagraph &&
      values.blogType &&
      values.blogCategory;

    if (isValid) {
      setPreviewData(values); // Store form data for preview
      setShowPreviewPanel(true); // Open preview panel
    } else {
      alert("Please fill in the required fields to preview the post.");
    }
  };

  // Merge state data into initialBlogData
  const initialValues = state || initialBlogData;

  const handleSubmit = async (values) => {
    setLoader(true);
    const formData = new FormData();

    // Append simple fields
    formData.append("createdBy", values.createdBy);
    formData.append("blogType", values.blogType);
    formData.append("blogCategory", values.blogCategory);
    formData.append("title", values.title);
    formData.append("summary", values.summary);
    formData.append("firstMainParagraph", values.firstMainParagraph);

    // Append main image (if exists)
    if (values.mainImage?.file) {
      formData.append("mainImage", values.mainImage.file);
    }

    // Append paragraphs (as JSON string)
    formData.append("paragraphs", JSON.stringify(values.paragraphs));

    // Serialize and append sub-sections (without images)
    const serializedSubSections = values.subSections.map((subSection) => ({
      ...subSection,
      image: undefined, // Exclude images from JSON serialization
    }));
    formData.append("subSections", JSON.stringify(serializedSubSections));

    values.subSections.forEach((subSection, index) => {
      if (subSection.image?.file) {
        formData.append(`subSectionImage_${index}`, subSection.image.file);
      }
    });

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    // try {
    //   await axios
    //     .post("/registrations/editBlogData", formData)
    //     .then((response) => {
    //       if (response.status !== 200) {
    //         throw new Error("Failed to submit blog post");
    //         setLoader(false);
    //       } else {
    //         notify(response);
    //         setLoader(false);
    //       }
    //     });
    // } catch (error) {
    //   console.error("Error submitting blog post:", error);
    // }
  };

  return (
    <div className='parentCreatBlog'>
      <div className='creatblogDiv'>
        {showPreviewpanel && previewData ? (
          <div className='previewSection'>
            <h3>{previewData.title}</h3>
            <p>{previewData.summary}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: previewData.firstMainParagraph,
              }}
            ></div>

            {/* Paragraphs */}
            {previewData.paragraphs?.length > 0 && (
              <div>
                {previewData.paragraphs.map((paragraph, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  ></div>
                ))}
              </div>
            )}

            {/* Subsections */}
            {previewData.subSections?.length > 0 && (
              <div>
                {previewData.subSections.map((section, index) => (
                  <div key={index}>
                    <h5>{section.title}</h5>
                    <img
                      src={section.image}
                      alt={`Subsection ${index + 1}`}
                      style={{ width: "100%", maxHeight: "200px" }}
                    />
                    {section.paragraphs.map((p, pIndex) => (
                      <div
                        key={pIndex}
                        dangerouslySetInnerHTML={{ __html: p }}
                      ></div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            <div className='blogPostBtn'>
              <button
                type='button'
                onClick={() => setShowPreviewPanel(false)}
                className='previewBtn'
              >
                <GoEyeClosed /> Close Preview
              </button>
              <button
                type='button'
                onClick={() => handleSubmit(previewData)}
                className='submitpostBtn'
              >
                <FaBlog /> Edit Blog Post
              </button>
            </div>
          </div>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => {
                console.log("Form values: ", values);
                onSubmit(values); // Pass data to parent or handle submission
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form className='blogPostArea'>
                  {/* Blog Info */}
                  {/* <div>
                <label>Blog ID</label>
                <Field name='blogId' type='text' disabled />
              </div> */}
                  <div className='blogCategoryselection'>
                    <div className='blogInputDiv'>
                      <label>Blog Type</label>
                      <Field name='blogType' type='text' />
                    </div>

                    <div className='blogInputDiv'>
                      <label>Category</label>
                      <Field name='blogCategory' type='text' />
                    </div>
                  </div>

                  <div className='blogInputDiv'>
                    <label>Title</label>
                    <Field name='title' type='text' />
                  </div>

                  <div className='blogInputDiv'>
                    <label>Summary</label>
                    <Field as='textarea' name='summary' type='text' />
                  </div>

                  <div className='blogInputDiv'>
                    <label>Main Image URL</label>
                    <Field name='mainImage' type='text' />
                  </div>

                  <div className='blogInputDiv' id='editor'>
                    <label htmlFor='firstMainParagraph'>First Paragraph</label>
                    {/* <Field
                                    as='textarea'
                                    name='firstMainParagraph'
                                    placeholder='Enter the first paragraph'
                                  /> */}
                    <ReactQuill
                      className='richtextArea'
                      name='firstMainParagraph'
                      placeholder='Enter the first paragraph'
                      theme='snow'
                      value={values.firstMainParagraph}
                      onChange={(content) =>
                        setFieldValue("firstMainParagraph", content)
                      }
                    />
                    {errors.firstMainParagraph &&
                      touched.firstMainParagraph && (
                        <div className='error'>{errors.firstMainParagraph}</div>
                      )}
                    <FieldArray name='paragraphs'>
                      {({ push, remove }) => (
                        <div style={{ width: "100%" }}>
                          {values.paragraphs.map((paragraph, index) => (
                            <div
                              key={index}
                              className='blogInputDiv'
                              style={{
                                width: "100%",
                                borderBottom: "2px solid grey",
                                marginBottom: "1%",
                              }}
                            >
                              <label>Paragraph {index + 1}</label>
                              <ReactQuill
                                className='richtextArea'
                                name={`paragraphs[${index}]`}
                                placeholder={`Enter paragraph ${index + 1}`}
                                theme='snow'
                                value={values.paragraphs[index]}
                                onChange={(content) =>
                                  setFieldValue(`paragraphs[${index}]`, content)
                                }
                              />
                              <button
                                type='button'
                                onClick={() => remove(index)}
                                className='removeField'
                              >
                                <MdPlaylistRemove />
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            type='button'
                            onClick={() => push("")}
                            className='addField'
                          >
                            Add Paragraph
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  {/* SubSections */}
                  <div className='subContentiv'>
                    <label>Subsections</label>
                    <FieldArray
                      name='subSections'
                      render={(arrayHelpers) => (
                        <div className='subContentiv'>
                          {values.subSections.map((section, index) => (
                            <div
                              key={index}
                              style={{ marginBottom: "20px" }}
                              className='blogInputDivsubCont'
                            >
                              <h4>Subsection {index + 1}</h4>
                              <div className='blogInputDiv'>
                                <label>Title</label>
                                <Field
                                  name={`subSections[${index}].title`}
                                  type='text'
                                />
                              </div>
                              <div className='blogInputDiv'>
                                <label>Image</label>
                                <Field
                                  name={`subSections[${index}].image`}
                                  type='text'
                                />
                              </div>

                              <FieldArray
                                name={`subSections[${index}].paragraphs`}
                                render={({
                                  paragraphHelpers,
                                  push,
                                  remove,
                                }) => (
                                  <div>
                                    {section.paragraphs.map(
                                      (paragraph, pIndex) => (
                                        <div key={pIndex}>
                                          <label>Paragraph {pIndex + 1}</label>
                                          <ReactQuill
                                            className='richtextArea'
                                            name={`subSections[${index}].paragraphs[${pIndex}]`}
                                            placeholder='Enter paragraph'
                                            theme='snow'
                                            value={
                                              values.subSections[index]
                                                .paragraphs[pIndex]
                                            } // Access the correct value from the form
                                            onChange={(content) =>
                                              setFieldValue(
                                                `subSections[${index}].paragraphs[${pIndex}]`,
                                                content
                                              )
                                            }
                                          />

                                          <button
                                            type='button'
                                            onClick={() => remove(pIndex)}
                                            className='removeField'
                                          >
                                            <MdPlaylistRemove />
                                            Remove sub paragraph
                                          </button>
                                        </div>
                                      )
                                    )}

                                    <button
                                      type='button'
                                      className='addSubParagraph'
                                      onClick={() => push("")}
                                    >
                                      <FaPlus className='addIcon' />
                                      Add sub paragraph
                                    </button>
                                  </div>
                                )}
                              />
                              <button
                                type='button'
                                className='removeField'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <MdPlaylistRemove />
                                Remove Subsection
                              </button>
                            </div>
                          ))}
                          <button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                title: "",
                                image: "",
                                paragraphs: [""],
                              })
                            }
                            className='addField'
                          >
                            <FaPlus className='addIcon' /> Add Sub Section
                          </button>
                        </div>
                      )}
                    />
                  </div>

                  {/* Submit Button */}
                  <div className='blogPostBtn'>
                    <button
                      type='button'
                      onClick={() => {
                        initialValues.current = values; // Save form data
                        handlePreview(values);
                      }}
                      className='previewBtn'
                    >
                      <FaEye /> Preview Post
                    </button>
                    <button type='submit' className='submitpostBtn'>
                      <FaBlog /> Submit Blog Post
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default BlogEditForm;
