import React, { useState, useRef, useEffect } from "react";
import { Formik, Field, FieldArray, Form } from "formik";
import * as Yup from "yup";
import { MdPlaylistRemove } from "react-icons/md";
import { FaEye, FaBlog, FaPlus } from "react-icons/fa";
import { GoEyeClosed } from "react-icons/go";
import "./CreateBlog.css";
import axios from "../../../utilities/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BookingLoader } from "../../../utilities/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Validation schema (optional)
const BlogSchema = Yup.object().shape({
  blogType: Yup.string().required("Blog type is required"),
  blogCategory: Yup.string().required("Blog category is required"),
  title: Yup.string().required("Blog title is required"),
  summary: Yup.string()
    .required("Title summary is required")
    .max(200, "Summary must be at most 200 characters"),
  mainImage: Yup.mixed().required("Main image is required"),
  firstMainParagraph: Yup.string().required("First paragraph is required"),
});

const AdminBlogPostForm = () => {
  const [previewData, setPreviewData] = useState(null); // State to hold preview data
  const [showPreviewpanel, setShowPreviewPanel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");
  const navigation = useNavigate();
  const location = useLocation();
 
  const blogCreator = JSON.parse(localStorage.getItem("blogUserData"));



  // const quill = new Quill("#editor", {
  //   theme: "snow",
  //   modules: {
  //     toolbar: [["bold", "italic", "underline"], [{ link: true }], ["clean"]],
  //   },
  // });

  const notify = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setTimeout(() => {
      navigation("/tiptopgo-blog", {});
    }, 2000);
  };

  const formValuesRef = useRef({
    createdBy: `${blogCreator.lastName} ${blogCreator.fistName}`,
    blogType: "",
    blogCategory: "",
    title: "",
    summary: "",
    mainImage: null,
    firstMainParagraph: "",
    paragraphs: [],
    subSections: [],
  });

  const handleSubmit = async (values) => {
    setLoader(true);
    const formData = new FormData();

    // Append simple fields
    formData.append("createdBy", values.createdBy);
    formData.append("blogType", values.blogType);
    formData.append("blogCategory", values.blogCategory);
    formData.append("title", values.title);
    formData.append("summary", values.summary);
    formData.append("firstMainParagraph", values.firstMainParagraph);

    // Append main image (if exists)
    if (values.mainImage?.file) {
      formData.append("mainImage", values.mainImage.file);
    }

    // Append paragraphs (as JSON string)
    formData.append("paragraphs", JSON.stringify(values.paragraphs));

    // Serialize and append sub-sections (without images)
    const serializedSubSections = values.subSections.map((subSection) => ({
      ...subSection,
      image: undefined, // Exclude images from JSON serialization
    }));
    formData.append("subSections", JSON.stringify(serializedSubSections));

    values.subSections.forEach((subSection, index) => {
      if (subSection.image?.file) {
        formData.append(`subSectionImage_${index}`, subSection.image.file);
      }
    });

    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    try {
      await axios
        .post("/registrations/createBlogData", formData)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Failed to submit blog post");
            setLoader(false);
          } else {
            notify(response);
            setLoader(false);
          }
        });
    } catch (error) {
      console.error("Error submitting blog post:", error);
    }
  };

  const handlePreview = (values) => {
    const isValid =
      values.title &&
      values.summary &&
      values.firstMainParagraph &&
      values.blogType &&
      values.blogCategory;

    if (isValid) {
      setPreviewData(values); // Store form data for preview
      setShowPreviewPanel(true); // Open preview panel
    } else {
      alert("Please fill in the required fields to preview the post.");
    }
  };

  const closePreview = () => {
    setShowPreviewPanel(false); // Close preview panel
  };

  return (
    <div className='parentCreatBlog'>
      {loader ? (
        <BookingLoader />
      ) : (
        <div className='creatblogDiv'>
          {showPreviewpanel ? (
            <div className='previewSection'>
              <h2>{previewData.title}</h2>
              <p>{previewData.summary}</p>
              {previewData.mainImage && (
                <img src={previewData.mainImage.previewURL} alt='Main Blog' />
              )}
              {/* Render HTML content in the first paragraph */}
              <p
                dangerouslySetInnerHTML={{
                  __html: previewData.firstMainParagraph,
                }}
              ></p>
              {/* Render HTML content in the list of paragraphs */}
              {previewData.paragraphs.map((para, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
              ))}
              {previewData.subSections.map((subHeader, index) => (
                <div key={index}>
                  <h3>{subHeader.title}</h3>
                  {subHeader.image && (
                    <img
                      src={subHeader.image.previewURL}
                      alt={`Sub-Header ${index + 1}`}
                    />
                  )}
                  {subHeader.paragraphs.map((para, pIndex) => (
                    <p
                      key={pIndex}
                      dangerouslySetInnerHTML={{ __html: para }}
                    ></p>
                  ))}
                </div>
              ))}
              <div className='blogPostBtn'>
                <button
                  type='button'
                  onClick={closePreview}
                  className='previewBtn'
                >
                  <GoEyeClosed /> Close Preview
                </button>
                <button
                  type='button'
                  onClick={() => handleSubmit(previewData)}
                  className='submitpostBtn'
                >
                  <FaBlog /> Submit Blog Post
                </button>
              </div>
            </div>
          ) : (
            <Formik
              initialValues={formValuesRef.current}
              enableReinitialize={false}
              validationSchema={BlogSchema}
              onSubmit={(values) => {
                formValuesRef.current = values; // Save form values
                handleSubmit(values);
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form className='blogPostArea'>
                  <h2>Create a Blog Post</h2>

                  <div className='blogCategoryselection'>
                    <div className='blogInputDiv'>
                      <label htmlFor='blogCategory'>Blog Category</label>
                      <Field as='select' name='blogCategory'>
                        <option value=''>Select a category</option>
                        <option value='Travel tips and hacks'>
                          Travel tips and hacks
                        </option>
                        <option value='Destination Guides'>
                          Destination Guides
                        </option>
                        <option value='Hotel reviews and recommendation'>
                          Hotel reviews and recommendation
                        </option>
                        <option
                          value='Travel stories and inspiration.
'
                        >
                          Travel stories and inspiration.
                        </option>
                      </Field>
                      {errors.blogCategory && touched.blogCategory && (
                        <div className='error'>{errors.blogCategory}</div>
                      )}
                    </div>

                    <div className='blogInputDiv'>
                      <label htmlFor='blogType'>Blog Type</label>
                      <Field as='select' name='blogType'>
                        <option value=''>Select a type</option>
                        <option value='trending'>Trending</option>
                        <option value='regular'>Regular</option>
                        <option value='news'>News</option>
                      </Field>
                      {errors.blogType && touched.blogType && (
                        <div className='error'>{errors.blogType}</div>
                      )}
                    </div>
                  </div>

                  <div className='blogInputDiv'>
                    <label htmlFor='title'>Blog Post Title</label>
                    <Field
                      name='title'
                      type='text'
                      placeholder='Enter blog title'
                    />
                    {errors.title && touched.title && (
                      <div className='error'>{errors.title}</div>
                    )}
                  </div>

                  <div className='blogInputDiv'>
                    <label htmlFor='summary'>Title Summary</label>
                    <Field
                      as='textarea'
                      name='summary'
                      placeholder='give a brief summary of the title'
                      onInput={(e) =>
                        setFieldValue("summary", e.target.value.slice(0, 200))
                      }
                    />
                    <div className='summaryErrorDiv'>
                      {errors.summary && touched.summary && (
                        <div className='error'>{errors.summary}</div>
                      )}
                      <p>{values.summary.length} / 200</p>
                    </div>
                  </div>

                  <div className='blogInputDiv'>
                    <label htmlFor='mainImage'>Blog Post Main Image</label>
                    <input
                      type='file'
                      accept='image/*'
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const previewURL = URL.createObjectURL(file); // Create preview URL
                          setFieldValue("mainImage", { file, previewURL }); // Store both
                        }
                      }}
                    />
                    {errors.mainImage && touched.mainImage && (
                      <div className='error'>{errors.mainImage}</div>
                    )}
                  </div>

                  <div className='blogInputDiv' id='editor'>
                    <label htmlFor='firstMainParagraph'>First Paragraph</label>
                    {/* <Field
                      as='textarea'
                      name='firstMainParagraph'
                      placeholder='Enter the first paragraph'
                    /> */}
                    <ReactQuill
                      className='richtextArea'
                      name='firstMainParagraph'
                      placeholder='Enter the first paragraph'
                      theme='snow'
                      value={values.firstMainParagraph}
                      onChange={(content) =>
                        setFieldValue("firstMainParagraph", content)
                      }
                    />
                    {errors.firstMainParagraph &&
                      touched.firstMainParagraph && (
                        <div className='error'>{errors.firstMainParagraph}</div>
                      )}
                    <FieldArray name='paragraphs'>
                      {({ push, remove }) => (
                        <div style={{ width: "100%" }}>
                          {values.paragraphs.map((paragraph, index) => (
                            <div
                              key={index}
                              className='blogInputDiv'
                              style={{
                                width: "100%",
                                borderBottom: "2px solid grey",
                                marginBottom: "1%",
                              }}
                            >
                              <label>Paragraph {index + 1}</label>
                              <ReactQuill
                                className='richtextArea'
                                name={`paragraphs[${index}]`}
                                placeholder={`Enter paragraph ${index + 1}`}
                                theme='snow'
                                value={values.paragraphs[index]} // Bind to Formik value
                                onChange={
                                  (content) =>
                                    setFieldValue(
                                      `paragraphs[${index}]`,
                                      content
                                    ) // Update Formik field
                                }
                              />
                              <button
                                type='button'
                                onClick={() => remove(index)}
                                className='removeField'
                              >
                                <MdPlaylistRemove />
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            type='button'
                            onClick={() => push("")}
                            className='addField'
                          >
                            Add Paragraph
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  <FieldArray name='subSections'>
                    {({ push, remove }) => (
                      <div className='subContentiv'>
                        {values.subSections.map((subHeader, index) => (
                          <div key={index} className='blogInputDivsubCont'>
                            <h3>Sub Section {index + 1}</h3>
                            <label>Sub Section Header</label>
                            <Field
                              name={`subSections[${index}].title`}
                              type='text'
                              placeholder={`Enter sub-header title ${
                                index + 1
                              }`}
                            />

                            <label>Sub Section Image</label>
                            <input
                              type='file'
                              accept='image/*'
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const previewURL = URL.createObjectURL(file);
                                  setFieldValue(`subSections[${index}].image`, {
                                    file,
                                    previewURL,
                                  });
                                }
                              }}
                            />

                            <FieldArray
                              name={`subSections[${index}].paragraphs`}
                            >
                              {({
                                push: pushParagraph,
                                remove: removeParagraph,
                              }) => (
                                <>
                                  {subHeader.paragraphs.map((_, pIndex) => (
                                    <div key={pIndex} className='blogInputDiv'>
                                      <label>Paragraph {pIndex + 1}</label>
                                      <ReactQuill
                                        className='richtextArea'
                                        name={`subSections[${index}].paragraphs[${pIndex}]`}
                                        placeholder='Enter paragraph'
                                        theme='snow'
                                        value={
                                          values.subSections[index].paragraphs[
                                            pIndex
                                          ]
                                        } // Access the correct value from the form
                                        onChange={(content) =>
                                          setFieldValue(
                                            `subSections[${index}].paragraphs[${pIndex}]`,
                                            content
                                          )
                                        }
                                      />

                                      <button
                                        type='button'
                                        onClick={() => removeParagraph(pIndex)}
                                        className='removeField'
                                      >
                                        <MdPlaylistRemove />
                                        Remove sub paragraph
                                      </button>
                                    </div>
                                  ))}
                                  <button
                                    type='button'
                                    className='addSubParagraph'
                                    onClick={() => pushParagraph("")}
                                  >
                                    <FaPlus className='addIcon' />
                                    Add sub paragraph
                                  </button>
                                </>
                              )}
                            </FieldArray>

                            <button
                              type='button'
                              onClick={() => remove(index)}
                              className='removeField'
                            >
                              <MdPlaylistRemove />
                              Remove Sub Section
                            </button>
                          </div>
                        ))}
                        <button
                          type='button'
                          onClick={() =>
                            push({ title: "", image: null, paragraphs: [] })
                          }
                          className='addField'
                        >
                          <FaPlus className='addIcon' /> Add Sub Section
                        </button>
                      </div>
                    )}
                  </FieldArray>

                  <div className='blogPostBtn'>
                    <button
                      type='button'
                      onClick={() => {
                        formValuesRef.current = values; // Save form data
                        handlePreview(values);
                      }}
                      className='previewBtn'
                    >
                      <FaEye /> Preview Post
                    </button>
                    <button type='submit' className='submitpostBtn'>
                      <FaBlog /> Submit Blog Post
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AdminBlogPostForm;
