import React, { useEffect, useState } from "react";
import {
  handleAddToCart,
  handleAdjustQty,
  GetCartData,
  handleRemoveFromCart,
  handleBreakFastQty,
} from "../../Redux/Actions/room_actions";
import { connect } from "react-redux";
import "../../IndividualHotel/HotelBooking/RoomCard.css";
import { AiTwotoneDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RoomCards = ({
  roomData,
  cart,
  totalBills,
  handleAddToCart,
  handleAdjustQty,
  handleRemoveFromCart,
  days,
  handleBreakFastQty,
  onData,
  selectRoom2,
  selectRoom2Setter,
  dateArr,
  totalAmount,
  selectedInfo,
  // condition,
}) => {
  const { roomNumber, roomCost, breakfastFlatCost, qty } = roomData;

  // console.log("roomData", cart);

  const [quantity, setQuantity] = useState(qty);
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectRoom, setSelectRoom] = useState(true);
  const [disable, setDisable] = useState(false);
  const [hasErro, setHasErro] = useState(false);
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [includeBreakFast, setIncludeBreakFast] = useState(false);
  const [isDateRangeAvailable, setIsDateRangeAvailable] = useState(false);
  const navigation = useNavigate();
  let breakfats = checkbox1Checked ? 0 : breakfastFlatCost * 1;

  let breakfats2 = checkbox2Checked ? 0 : breakfastFlatCost * 2;
  let numOfDays = days;
  let breakFastQty = 1;
  let breakFastQtyNT = 0;

  let breakFastQty2 = 2;

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
    if (event.target.checked) {
      setCheckbox2Checked(false);
      handleBreakFastQty(roomData.roomTypeId, breakFastQty, numOfDays);
      onData(breakfats);
    } else {
      handleBreakFastQty(roomData.roomTypeId, breakFastQtyNT, numOfDays);
    }
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
    if (event.target.checked) {
      setCheckbox1Checked(false);
      handleBreakFastQty(roomData.roomTypeId, breakFastQty2, numOfDays);
      onData(breakfats2);
    } else {
      handleBreakFastQty(roomData.roomTypeId, breakFastQtyNT, numOfDays);
    }
  };

  const showBtn = () => {
    setSelectRoom(false);
  };

  const showBtn2 = () => {
    setSelectRoom(true);
  };

  const showBtn3 = () => {
    selectRoom2Setter(true);
  };

  const onChangeHandler = (e) => {
    setQuantity(e.target.value);
    handleAdjustQty(roomData.roomTypeId, e.target.value);

    handleBreakFastQty(roomData.roomTypeId, breakFastQtyNT, numOfDays);
    setCheckbox1Checked(false);
    setCheckbox2Checked(false);

    if (roomData.availableRooms < e.target.value) {
      setHasErro(true);
      setQuantity(roomData.availableRooms);
      handleAdjustQty(roomData.roomTypeId, roomData.availableRooms, numOfDays);
    } else {
      setHasErro(false);
    }
  };
  const dateRange = localStorage.getItem("dateRange");
  useEffect(() => {
    // Fetch dateRange from localStorage and update state

    setIsDateRangeAvailable(Boolean(dateRange));
  }, [dateRange]);

  const myData = GetCartData();

  // let totalAmount;

  // totalAmount = totalBills * numOfDays;

  const notify = (roomTypeName) => {
    toast.success(`${roomTypeName} Room  is Added, increase number`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const removeRoomNotify = (roomTypeName) => {
    toast.warn(`${roomTypeName} Room has been removed`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const checkEmptyRoom = (roomTypeName) => {
    if (roomTypeName !== " ") {
      setDisableBtn(false);
    }
  };
  useEffect(() => {
    const checkRoomQty = () => {
      if (roomData.availableRooms < 1) {
        setDisable(true);
      }

      if (
        roomData.breakfastFlatCost === null ||
        roomData.breakfastFlatCost <= 0 ||
        !roomData.breakfastFlatCost
      ) {
        setIncludeBreakFast(false);
      } else {
        setIncludeBreakFast(true);
      }
    };
    checkRoomQty();
  }, [roomData]);

  const selectRoomNavigate = () => {
    // console.log("navigatetotalAmount", totalAmount);
    // console.log("navigatetotalAmount ==>", dateArr, selectedInfo);
    // condition();
    setTimeout(() => {
      navigation(`/book`, {
        state: {
          totalAmount,
          days,
          selectedInfo,
          dateArr,
        },
      });
    }, 1000);
  };

  return (
    <>
      <div className="parent_room_button">
        <div className="secondParent" style={{ width: "100%" }}>
          <div className="btn_div" style={{ width: "100%" }}>
            <div className="btn_div2">
              {disable ? (
                <button
                  className="select_button2"
                  onClick={() =>
                    handleAddToCart({
                      ...roomData,
                      type: "room",
                      price: roomCost,
                      notify: notify(roomData.roomTypeName),
                      numOfDays: days,
                      addqty: setQuantity(1),
                      setShow: showBtn(),
                    })
                  }
                  disabled={disable}
                >
                  select room {roomNumber}
                </button>
              ) : (
                <button
                  className={selectRoom ? "select_button" : "hideBtn"}
                  disabled={!isDateRangeAvailable || days === 0}
                  onClick={() =>
                    handleAddToCart({
                      ...roomData,
                      type: "room",
                      price: roomCost,
                      notify: notify(roomData.roomTypeName),
                      numOfDays: days,
                      addqty: setQuantity(1),
                      setShow: showBtn(),
                      navigate: selectRoomNavigate(),
                    })
                  }
                >
                  select room {roomNumber}
                </button>
              )}
              <ToastContainer />
              <div className="removequantityDIv">
                <div className="seconddivremovdiv">
                  {/* <button
                    className='removebtn'
                    onClick={() =>
                      handleRemoveFromCart(
                        roomData.roomTypeId,
                        removeRoomNotify(roomData.roomTypeName),
                        setCheckbox2Checked(false),
                        setCheckbox1Checked(false),
                        showBtn2(),
                        checkEmptyRoom(roomData.roomTypeName)
                      )
                    }
                    disabled={disableBtn}
                  >
                    <AiTwotoneDelete className='delbtn' />
                  </button> */}

                  {/* <div className='inputField_div'>
                    <input
                      type='number'
                      min='1'
                      max='100'
                      value={quantity || 1}
                      onChange={onChangeHandler}
                      className='inputField'
                    />
                  </div> */}
                </div>

                <div className="includeBreakdiv">
                  {includeBreakFast && (
                    <div className="checkBoxes">
                      <label className="selectBreak">
                        <input
                          className="mainBox"
                          type="checkbox"
                          checked={checkbox1Checked}
                          onChange={handleCheckbox1Change}
                        />
                        Add 1 breakfast
                      </label>

                      <label className="selectBreak">
                        <input
                          type="checkbox"
                          checked={checkbox2Checked}
                          onChange={handleCheckbox2Change}
                        />
                        Add 2 breakfast
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {hasErro ? (
            <span className="indicate">
              Quantity can't be greater <br /> than availableRooms
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

const calcQuickTotal = (items) => {
  if (items) {
    return items.reduce((a, b) => {
      if (b.discountRate) {
        return a + b.qty * b.price; // NB: b.discountPrice
      } else {
        return a + b.qty * b.price;
      }
    }, 0);
  }
  return 0;
};

const mapStateToProps = (state) => {
  const cart = state.cart;
  return {
    totalBills: calcQuickTotal(state.cart),
    cart,
  };
};

export default connect(mapStateToProps, {
  handleAddToCart,
  handleBreakFastQty,
  handleAdjustQty,
  handleRemoveFromCart,
})(RoomCards);
