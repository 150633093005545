import React, { useState, useEffect } from "react";
import axios from "../../../utilities/axios";
import "./BlogListView.css";
import BlogModal from "./BlogModal";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const BlogListView = () => {
  const [actionMenu, setActionMenu] = useState(null);
  const [ShowModal, setShowModal] = useState(false);
  const [allBlogPost, setAllBlogPost] = useState([]);
  const [buttonAction, setButtonAction] = useState("");
  const [blogId, setBlogId] = useState("");

  const navigation = useNavigate();

  useEffect(() => {
    const getAllBlogPost = async () => {
      const getALlBlog = await axios.get("/registrations/getAllBlogs");
      setAllBlogPost(getALlBlog.data.data);
      // console.log("getALlBlog", getALlBlog.data.data);
    };
    getAllBlogPost();
  }, []);

  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.substring(0, limit)}...` : text;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const toggleActionMenu = (index) => {
    setActionMenu(actionMenu === index ? null : index);
  };

  const handleActionClick = (action, blogId) => {
    setButtonAction(action);
    setBlogId(blogId);
    setShowModal(true);
    setActionMenu(null); // Close the menu
  };

  const handleEditBlog = (blog) => {
    navigation(`/LoginDash/edit-blog-post`, { state: blog });
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".actionBtn") &&
      !event.target.closest(".actionMenu")
    ) {
      setActionMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className='parentBlogList'>
      <div className='blogListDiv'>
        <table className='blogTable'>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Blog Title</th>
              <th>Blog category</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th>Blog Status</th>
              <th>Blog Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allBlogPost &&
              allBlogPost.map((blog, index) => (
                <tr key={blog.blogId}>
                  <td>{index + 1}</td>
                  <td>{blog.title}</td>
                  <td>{blog.blogCategory}</td>
                  <td>{blog.createdBy}</td>
                  <td>{moment(blog.createdDateTime).format("Do-MMM-YYYY")}</td>
                  <td>{blog.status}</td>
                  <td>{blog.blogType}</td>
                  <td>
                    <button
                      className='actionBtn'
                      onClick={() => toggleActionMenu(index)}
                    >
                      Actions
                    </button>
                    {actionMenu === index && (
                      <div className='actionMenu'>
                        {/* <span
                          className="optionspan"
                          onClick={() => handleActionClick("suspend post")}
                        >
                          suspend post
                        </span> */}
                        <span
                          className='optionspan'
                          onClick={() =>
                            handleActionClick("update status", blog.blogId)
                          }
                        >
                          update status
                        </span>
                        <span
                          className='optionspan'
                          onClick={() =>
                            handleActionClick("update blog type", blog.blogId)
                          }
                        >
                          update blog type
                        </span>
                        {/* <span
                          className='optionspan'
                          onClick={() => handleEditBlog(blog)}
                        >
                          Edit Blog post
                        </span> */}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {ShowModal && (
        <BlogModal
          closeModal={handleCloseModal}
          buttonAction={buttonAction}
          blogId={blogId}
        />
      )}
    </div>
  );
};

export default BlogListView;
