const BlogUser = [
  {
    email: "blogcreator@tiptopgo.com",
    password: "blogcreator8401",
    userId: "tiptopgo890322",
    userType: "blogCreator",
    fistName: "Ayo",
    lastName: "sparkle",
    gender: "male",
    status: "active",
  },

  {
    email: "secondblogcreator@tiptopgo.com",
    password: "secondblogcreator8401",
    userId: "tiptopgo890322",
    userType: "blogCreator",
    fistName: "alice",
    lastName: "jason",
    gender: "female",
    status: "inactive",
  },
];

export default BlogUser;
