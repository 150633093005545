import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Surpport = () => {
  return (
    <div style={styles.container}>
      <a
        href='https://api.whatsapp.com/send/?phone=+2348135936594&text=Hi'
        target='_blank'
        rel='noopener noreferrer'
        style={styles.link}
      >
        <FaWhatsapp size={40} color='#25D366' />
        <span style={styles.text}>For Support Chat</span>
      </a>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between icon and text
    backgroundColor: "#fff",
    borderRadius: "30px",
    padding: "10px 15px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  text: {
    fontSize: "16px",
    color: "#000",
    fontWeight: "bold",
  },
};

export default Surpport;
