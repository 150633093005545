import React, { useState } from "react";
import "./LoginDashBoard.css";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaThList,
} from "react-icons/fa";
import { MdFreeCancellation } from "react-icons/md";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import NavBar from "../../../HomePage/NavBar/NavBar";
import Footer from "../../../HomePage/Footer/Footer";
import Subscribe from "../../../HomePage/Subscribe/Subscribe";
import GetApp from "../../../HomePage/Get App/GetApp";
import { FaBlog } from "react-icons/fa6";
import { AiOutlineLogout } from "react-icons/ai";
import { CiCircleList } from "react-icons/ci";

const LoginDashBoard = ({ children }) => {
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/LoginDash/main-dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/LoginDash/userprofile",
      name: "Profile",
      icon: <FaUserAlt />,
    },
    {
      path: "/LoginDash/favourite-hotel",
      name: "Favourite",
      icon: <FaRegChartBar />,
    },
    {
      path: "/LoginDash/history",
      name: "Reservation",
      icon: <FaThList />,
    },
    {
      path: "/LoginDash/cancel-reservation",
      name: "Cancel",
      icon: <MdFreeCancellation />,
    },
    // {
    //   path: "/LoginDash/create-blog-post",
    //   name: "Blog center",
    //   icon: <FaBlog />,
    // },
  ];

  const blogMenuItem = [
    {
      path: "/LoginDash/create-blog-post",
      name: "Create Blog",
      icon: <FaBlog />,
    },
    {
      path: "/LoginDash/view-blog-list",
      name: "View All Blog",
      icon: <CiCircleList />,
    },
    {
      path: "/tiptopgo-blog",
      name: "Log out",
      icon: <AiOutlineLogout color="red" />,
    },
  ];

  const getMenuItems = () => {
    const blogUserData = localStorage.getItem("blogUserData");
    const userData = localStorage.getItem("userData");

    if (blogUserData) {
      return blogMenuItem;
    } else if (userData) {
      return menuItem;
    } else {
      return [];
    }
  };

  const filteredMenuItems = getMenuItems();

  return (
    <>
      <NavBar />
      <div className="containerDashBoard">
        <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
          <div className="top_section">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
              Menu
            </h1>
            <div
              style={{ marginLeft: isOpen ? "10%" : "0px" }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {filteredMenuItems.map((item, index) => (
            <NavLink
              to={item.name === "Log out" ? "/tiptopgo-blog" : item.path} // Prevent navigation for "Log out"
              key={index}
              className="link"
              activeclassname="active"
              onClick={() => {
                if (item.name === "Log out") {
                  // Clear local storage and navigate to login
                  localStorage.removeItem("blogUserData");
                  // navigation("/tiptopgo-blog"); // Replace "/login" with your actual login route
                }
              }}
            >
              <div style={{ display: isOpen ? "" : "block" }} className="icon">
                {item.icon}
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        {/* <main>{children}</main> */}
        <Outlet />
      </div>
      <Subscribe />
      <GetApp />
      <Footer />
    </>
  );
};

export default LoginDashBoard;
