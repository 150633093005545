import React, { useState } from "react";
import "./BlogModal.css";
import axios from "../../../utilities/axios";

const BlogModal = ({ closeModal, buttonAction, blogId }) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (
      (buttonAction === "update status" && !selectedStatus) ||
      (buttonAction !== "update status" && !selectedCategory)
    ) {
      alert("Please select an option before proceeding.");
      return;
    }

    const payload =
      buttonAction === "update status"
        ? { actionStatus : selectedStatus, blogId }
        : { actionType: selectedCategory, blogId };

    try {
      setLoading(true);
      const response = await axios.post("registrations/editBlog", payload);
      // console.log("Response from backend:", response.data);
      closeModal();
    } catch (error) {
      // console.error("Error sending data to backend:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bookingModalParent">
      <div className="bookingModalDiv">
        <div className="cancelBtnDiv">
          <button onClick={closeModal} className="closeModalBtn">X</button>
        </div>

        <h3 className="modalTitle">
          {buttonAction === "update status" ? "Update Blog Status" : "Update Blog Type"}
        </h3>

        {buttonAction === "update status" ? (
          <div className="selectContainer">
            <label htmlFor="statusSelect" className="selectLabel">Select Post Status</label>
            <select
              id="statusSelect"
              className="selectInput"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="suspend">Suspend Post</option>
              <option value="active">Activate Post</option>
              <option value="delete">Delete Post</option>
            </select>
          </div>
        ) : (
          <div className="selectContainer">
            <label htmlFor="categorySelect" className="selectLabel">Select Post Category</label>
            <select
              id="categorySelect"
              className="selectInput"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="trending">Trending</option>
              <option value="news">News</option>
              <option value="regular">Regular</option>
            </select>
          </div>
        )}

        <div className="proceedDiv">
          <button onClick={closeModal} className="cancelproccedBtnDiv">Cancel</button>
          <button
            onClick={handleSubmit}
            className="proceedBtnDiv"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Proceed"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogModal;


