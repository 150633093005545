import React from "react";
import "./Support_Number.css";

export const Support_Number = () => {
  return (
    <div className='supportNum'>
      <a href='https://api.whatsapp.com/send/?phone=+2348135936594&text=Hi'>
        for support and enquiry chat : +234 8135936594
      </a>
    </div>
  );
};

export const Support_Number_Mobile = () => {
  const supportPhoneNumber = "08135936594";
  return (
    <div className='mobileSupportNum'>
      <a href='https://api.whatsapp.com/send/?phone=+2348135936594&text=Hi'>
        for support and enquiry chat : +234 8135936594
      </a>
    </div>
  );
};
