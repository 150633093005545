import React, { useEffect, useState } from "react";
import Aboutus from "./AboutUs/Aboutus";
import Destinations from "./Destinations/Destinations";
import Footer from "./Footer/Footer";
import GetApp from "./Get App/GetApp";
import Hero from "./Hero/Hero";
import NavBar from "./NavBar/NavBar";
import PopularCity from "./Popular city/PopularCity";
import Search_Hotel from "./Search/Search";
import Subscribe from "./Subscribe/Subscribe";
import Todays_deal from "./Top/Todays_deal";
import "./HomeRoutes.css";
// import {handleClearStore} from '../../Redux/Actions/room_actions'
import { useLocation } from "react-router-dom";
import { handleClearStore } from "../Redux/Actions/room_actions";
import { connect } from "react-redux";
import BookingModal from "../Book/BookingModal";
import EmailTesting from "../EmailTesting";
import JobsHero from "./JobsModule/JobHeader/JobsHero";
import JobsButton from "./JobsModule/JobsButton/JobsButton";

const HomeRoute = ({ handleClearStore }) => {
  const [reloadKey, setReloadKey] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setReloadKey((prev) => prev + 1); // Increment key to force reload
    }
  }, [location.pathname]);

  useEffect(() => {
    localStorage.removeItem("dateRange");
    handleClearStore();
  }, []);

  return (
    <div className="homepageCss">
      <NavBar />
      {/* <JobsHero/> */}
      <Hero key={reloadKey} />
      {/* <JobsButton/> */}
      {/* <Search_Hotel/> */}
      <Destinations />
      <Todays_deal />
      {/* <Aboutus/> */}
      {/* <PopularCity/> */}
      <Subscribe />
      {/* <EmailTesting/>   */}
      <GetApp />
      <Footer />
    </div>
  );
};

// const mapStateToProps = (state) => {
//   const cart = state.rootReducer.cart;
//   return {
//     cart
//   };
// };

export default connect(null, { handleClearStore })(HomeRoute);
