import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../utilities/axios";
import moment from "moment/moment";

const BlogPostComment = ({ blogContentId }) => {
  const [allBlogCommet, setAllBlogCommet] = useState([]);

  useEffect(() => {
    getAllBlogComment();
  }, []);

  const getAllBlogComment = async () => {
    // console.log("blogContentId", blogContentId);
    await axios
      .get(`registrations/getBlogComments/${blogContentId}`)
      .then((response) => {
        // console.log("respon get all", response);
        setAllBlogCommet(response.data);
      });
  };
  const formik = useFormik({
    initialValues: {
      blogId: blogContentId,
      name: "",
      email: "",
      comment: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      comment: Yup.string()
        .min(10, "Comment must be at least 10 characters")
        .required("Comment is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      // console.log("value", values);
      try {
        const response = await axios
          .post("registrations/createBlogComment", values)
          .then((response) => {
            // console.log("response", response);
            if (response.data.success === true) {
              resetForm();
              getAllBlogComment();
            }
            // console.log("Comment submitted successfully:", values);
          });
      } catch (error) {
        console.error("Error submitting comment:", error);
      }
    },
  });

  return (
    <div className="commentSection">
      <h3>comments</h3>
      <section className="commentListSec">
        {allBlogCommet &&
          allBlogCommet?.map((coment) => (
            <div className="eachComment">
              <div className="commentNamedate">
                <span>{coment.name}: </span>{" "}
                <span className="blogPostDate">
                  {moment(coment.createdDateTime).format("YYYY-MMM-DD : h:s A")}
                </span>{" "}
              </div>
              <p className="mainComment"> {coment.comment} </p>
            </div>
          ))}
      </section>
      <h3>Leave A Reply</h3>
      <form onSubmit={formik.handleSubmit} className="commentparentdiv">
        <div className="commentBioparentdiv">
          <div className="commentiinputdiv">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="commentiinputdiv">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div className="textareadiv">
          <label htmlFor="comment">Comment</label>
          <textarea
            id="comment"
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.comment && formik.errors.comment ? (
            <div className="error">{formik.errors.comment}</div>
          ) : null}
        </div>
        <div className="commentsubmitbtn">
          <button type="submit">Post Comment</button>
        </div>
      </form>
    </div>
  );
};

export default BlogPostComment;
