import { AiOutlineMenuFold } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { useState, useRef } from "react";
import { useOnHoverOutside } from "../../hooks/useOnHoverOutside";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  handleAddToCart,
  handleAdjustQty,
  GetCartData,
  handleClearStore,
} from "../../Redux/Actions/room_actions";
import { connect } from "react-redux";
import "./MobileMenu.css";
import Support_Number from "./Support_Number";

const MobileMenu = ({ handleClearStore }, props) => {
  const [open, setOpen] = useState(false);
  const [showlink, setShowLink] = useState(false);

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  //console.log('isMenuDropDownOpen2233', isMenuDropDownOpen);
  const navigate = useNavigate();
  const history = useLocation();
  const [currentPage, setCurrentPage] = useState(history.pathname);

  // Function to close dropdown
  const closeHoverMenu = (e) => {
    e.preventDefault();
    setMenuDropDownOpen(false);
  };

  const getUserData = () => {
    const userData = localStorage.getItem("userData");

    navigate("/book", {
      state: {
        userData,
      },
    });
  };

  const logOut = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("loggInStatus");
    localStorage.removeItem("historyData");
    handleClearStore();
    navigate("/", { state: {} });
  };

  const isLogin = localStorage.getItem("loggInStatus");

  //console.log('isMenuDropDownOpen', isMenuDropDownOpen);

  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

  const hamburger = (
    <AiOutlineMenuFold
      color="white"
      size="40px"
      className="Hamburger"
      onClick={() => setOpen(!open)}
    />
  );

  const closeIcon = (
    <AiOutlineClose
      color="white"
      size="35px"
      className="Hamburger Hamburger2"
      onClick={() => setOpen(!open)}
    />
  );

  const closeMobileMenu = () => setOpen(false);

  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };

  return (
    <div className="menu2">
      {open ? closeIcon : hamburger}
      {open && (
        <div
          className="disp"
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.05 }}
        >
          <motion.a
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: 0.1 }}
            onClick={() => props.isMobile && closeMobileMenu()}
          >
            {" "}
            <li>
              <Link to="/tiptopgo-blog">View Blog</Link>
            </li>{" "}
          </motion.a>
          <motion.a
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: 0.25 }}
            onClick={() => props.isMobile && closeMobileMenu()}
          >
            {" "}
            <li>
              <Link to="/contact">Support</Link>
            </li>{" "}
          </motion.a>
          <motion.a
            initial={animateFrom}
            animate={animateTo}
            transition={{ delay: 0.45 }}
            onClick={() => props.isMobile && closeMobileMenu()}
          >
            {" "}
            <li>
              <Link to="/check">Find Reservation</Link>
            </li>{" "}
          </motion.a>

          {isLogin === "true" ? (
            <motion.a
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.55 }}
              onClick={() => props.isMobile && closeMobileMenu()}
            >
              <Link to="/LoginDash">
                <li className="acc">Account</li>
              </Link>
            </motion.a>
          ) : null}

          <div className="regLogMobile">
            <motion.a
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.65 }}
              onClick={() => props.isMobile && closeMobileMenu()}
            >
              <Link
                to="/register-hotel"
                style={{
                  display: currentPage === "/register-hotel" ? "none" : "block",
                }}
              >
                <button className="login">Register Hotel</button>
              </Link>
            </motion.a>
            <motion.a
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.75 }}
              onClick={() => props.isMobile && closeMobileMenu()}
            >
              {isLogin === "true" ? (
                <li>
                  <button className="login signout" onClick={logOut}>
                    Sign out
                  </button>
                </li>
              ) : (
                <li>
                  <Link
                    to="/Login"
                    style={{
                      display: currentPage === "/book" ? "none" : "block",
                    }}
                  >
                    <button className="login regSignIn">
                      Sign in / Register
                    </button>
                  </Link>
                </li>
              )}
            </motion.a>

            {/* <motion.div
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.65 }}
            >
              <Support_Number />
            </motion.div> */}
          </div>
        </div>
      )}
    </div>
  );
};

// const mapStateToProps = (state) => {
//     const cart = state.rootReducer.cart;
//     return cart
//   };

export default connect(null, { handleClearStore })(MobileMenu);
