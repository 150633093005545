import React from "react";
import "./Blog.css";
import { useLocation } from "react-router-dom";
import NavBar from "../../HomePage/NavBar/NavBar";
import Footer from "../../HomePage/Footer/Footer";
import GetApp from "../../HomePage/Get App/GetApp";
import TrendingPost from "../TrendingPost.jsx/TrendingPost";
import BlogPostComment from "../BlogPostComment/BlogPostComment";
import tiptopgo from "../../assets/faviconMani.jpg";
import moment from "moment";

const Blog = () => {
  const location = useLocation();
  const { blogContent } = location.state || {};

  // console.log("blogContent", blogContent);
  return (
    <>
      <NavBar />

      <div className='parentBlog'>
        <div className='blogherosection'>
          <div className='blogimgtext'>
            <h3>{blogContent.title}</h3>
            <div className='blogimgtextparadiv'>
              <p>{blogContent.summary}</p>
            </div>
            <div className='blogpostauthor'>
              <div className='postauthorDetails'>
                <div className='authorimg'>
                  <img src={tiptopgo} alt='' />
                </div>
                <div>
                  {moment(blogContent.createdDateTime).format("DD MMM YYYY")}
                </div>
              </div>
            </div>
            <div className='blogheroimage'>
              <img src={blogContent.mainImage} alt='' />
            </div>
          </div>
        </div>
        <div className='firstparagraph'>
          <div className='postcontent'>
            {/* Render HTML in first paragraph */}
            <p
              dangerouslySetInnerHTML={{
                __html: blogContent.firstMainParagraph,
              }}
            ></p>

            {/* Render HTML in paragraphs array */}
            {blogContent?.paragraphs?.map((paragraph, index) => (
              <p
                key={index}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              ></p>
            ))}

            {/* Render HTML in sub-sections */}
            {blogContent.subSections.map((subHeader, index) => (
              <div key={index}>
                <h2 className='subSectionTitle'>{subHeader.title}</h2>

                {subHeader.image && (
                  <div className='paragraphImg'>
                    <img
                      src={subHeader.image}
                      alt={`Sub-Header ${index + 1}`}
                    />
                  </div>
                )}
                {subHeader.paragraphs.map((para, pIndex) => (
                  <p
                    key={pIndex}
                    dangerouslySetInnerHTML={{ __html: para }}
                  ></p>
                ))}
              </div>
            ))}

            <div>
              <BlogPostComment blogContentId={blogContent.blogId} />
            </div>
          </div>
          <div className='trendingComdiv'>
            <TrendingPost />
          </div>
        </div>
      </div>

      <GetApp />
      <Footer />
    </>
  );
};

export default Blog;
